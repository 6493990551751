import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { openToastify } from 'components/toast/OpenToast';
import { postPreventive } from 'services/stepsAPI';

const initialState: {
  preventive: {
    data: any;
    status: string;
  };
  roomSelected: {
    data: any;
    status: string;
  };
} = {
  preventive: {
    data: null,
    status: 'idle'
  },
  roomSelected: {
    data: null,
    status: 'idle'
  }
};

export const postPreventiveState = createAsyncThunk(
  'building/postPreventive',
  async (body: any) => {
    try {
      const response: any = await postPreventive(body);
      return response.data;
    } catch (error: any) {
      openToastify('danger', 'something_missing', error?.status);
      return Promise.reject(error.data ? error.data : error);
    }
  }
);

export const preventiveSlice = createSlice({
  name: 'preventive',
  initialState,
  reducers: {
    setPreventive: (state, action) => {
      state.preventive.status = 'set';
      state.preventive.data = action.payload;
      localStorage.setItem('preventiveData', JSON.stringify(action.payload));
    },
    setRoomSelected: (state, action) => {
      state.roomSelected.status = 'set';
      state.roomSelected.data = action.payload;
      localStorage.setItem('room_selected', JSON.stringify(action.payload));
    },
    resetPreventive: (state) => {
      state.preventive.data = null;
      localStorage.removeItem('preventiveData');
    }
  },
  extraReducers(builder) {
    builder
      .addCase(postPreventiveState.pending, (state) => {
        state.preventive.status = 'loading';
      })
      .addCase(postPreventiveState.fulfilled, (state, action) => {
        state.preventive.status = 'success';
        state.preventive.data = action.payload;
        localStorage.setItem('preventiveData', JSON.stringify(action.payload));
      })
      .addCase(postPreventiveState.rejected, (state) => {
        state.preventive.status = 'rejected';
      });
  }
});

export const { setPreventive, resetPreventive, setRoomSelected } = preventiveSlice.actions;
export const preventiveState = (state: RootState) => state.preventive.preventive;
export const roomSelectedState = (state: RootState) => state.preventive.roomSelected;

export default preventiveSlice.reducer;
