import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { openToastify } from 'components/toast/OpenToast';
import { getModulesByUtmCampaign, getSession } from 'services/homepageApi';

const initialState: {
  session: {
    data: any;
    status: string;
  };
  isConfigStart: {
    data: boolean;
    status: string;
  };
  isNewTheme: {
    data: string;
    status: string;
  };
  modulesDisplay: {
    data: any;
    status: string;
  };
} = {
  session: {
    data: null,
    status: 'idle'
  },
  isConfigStart: {
    data: false,
    status: 'idle'
  },
  isNewTheme: {
    data: 'false',
    status: 'idle'
  },
  modulesDisplay: {
    data: null,
    status: 'idle'
  }
};

export const getSessionState = createAsyncThunk('homepage/getSession', async (body: any) => {
  try {
    const response: any = await getSession(body);
    return response.data;
  } catch (error: any) {
    openToastify('danger', 'something_missing');
    return Promise.reject(error.data ? error.data : error);
  }
});

export const getModulesByUtmCampaignState = createAsyncThunk(
  'homepage/getModulesByUtmCampaign',
  async (body: any) => {
    try {
      const response: any = await getModulesByUtmCampaign(body);
      return response.data;
    } catch (error: any) {
      openToastify('danger', 'something_missing');
      return Promise.reject(error.data ? error.data : error);
    }
  }
);

export const homepageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    resetSession: (state) => {
      state.session.data = null;
      state.session.status = 'idle';
    },
    setIsConfigStart: (state, action) => {
      state.isConfigStart.data = action.payload;
    },
    resetIsConfigStart: (state) => {
      state.isConfigStart.data = false;
    },
    setIsNewTheme: (state, action) => {
      state.isNewTheme.data = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getSessionState.pending, (state) => {
        state.session.status = 'loading';
      })
      .addCase(getSessionState.fulfilled, (state, action) => {
        state.session.status = 'success';
        state.session.data = action.payload;
        localStorage.setItem(
          'preventiveData',
          JSON.stringify({ results: action.payload.results.cart })
        );
        localStorage.setItem('session', JSON.stringify(action.payload.results));
      })
      .addCase(getSessionState.rejected, (state) => {
        state.session.status = 'rejected';
      })
      .addCase(getModulesByUtmCampaignState.pending, (state) => {
        state.modulesDisplay.status = 'loading';
      })
      .addCase(getModulesByUtmCampaignState.fulfilled, (state, action) => {
        state.modulesDisplay.status = 'success';
        state.modulesDisplay.data = action.payload.results;
      })
      .addCase(getModulesByUtmCampaignState.rejected, (state) => {
        state.modulesDisplay.status = 'rejected';
      });
  }
});

export const { resetSession, setIsConfigStart, resetIsConfigStart, setIsNewTheme } =
  homepageSlice.actions;
export const sessionState = (state: RootState) => state.homepage.session;
export const isConfigStartState = (state: RootState) => state.homepage.isConfigStart;
export const isNewThemeState = (state: RootState) => state.homepage.isNewTheme;
export const modulesDisplayState = (state: RootState) => state.homepage.modulesDisplay;

export default homepageSlice.reducer;
